import React from "react";
import "./About.css";
import { useTranslation } from "react-i18next";
import Image1 from "../../assets/image1.png";
import Image2 from "../../assets/image2.png";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const fadeImages = [
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%201.png?alt=media&token=34230e12-26db-4ab1-9233-401847042048"
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%202.png?alt=media&token=25012b75-90c7-4741-9379-a049dafea642"
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%203.png?alt=media&token=f70c1da9-2c90-4ecf-a6a5-883ce3d0bab1"
  },
];

const About = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="About">
        <div className="image-text-section">
          <div className="home-intro">
            <span>{t("about_us")}</span>
          </div>
        </div>
        <div className="intro">
          <div>{t("welcome")}</div>
        </div>
        <div className="slide-container">
          <Fade>
            {fadeImages.map((fadeImage, index) => (
              <div key={index}>
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={fadeImage.url}
                />
              </div>
            ))}
          </Fade>
        </div>
        <div className="about-description">
          <div>
            {t("about_description")}
          </div>
          <div className="image-gallery-content">
            <div className="image-content">
              <img alt="img1" src="https://firebasestorage.googleapis.com/v0/b/ama-international-77c43.appspot.com/o/IMG-20240705-WA0046.jpg?alt=media&token=4bc4c168-91fa-4264-a7a0-d4ec48c8f886" width="720" />
            </div>
            <div className="image-content">
              <img alt="img1" src="https://firebasestorage.googleapis.com/v0/b/ama-international-77c43.appspot.com/o/IMG-20240705-WA0040.jpg?alt=media&token=804185ee-6e7e-429a-9199-54415f909a8d" width="720" />
            </div>
          </div>
          <div className="about-heading">{t("about_history")}</div>
          <div>{t("about_history_description")}</div>
          <div className="about-heading">{t("about_mission")}</div>
          <div>{t("about_mission_description")}</div>
          <div className="about-heading">{t("about_vision")}</div>
          <div>{t("about_vision_description")}</div>
          <div className="about-heading">{t("about_team")}</div>
          <div>{t("about_team_description")}</div>
          <div>  
            <br/>
            {t("about_thank")}
          </div>
        </div>
        <div className="image-gallery">
          <div className="image">
            <img alt="img1" src={Image1} width="720" />
          </div>
          <div className="image">
            <img alt="img1" src={Image2} width="720" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
