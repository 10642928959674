import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Home.css";
import { programsData } from "../../data/programsData";
import { MdFrontLoader } from "react-icons/md";
import { TbBackhoe } from "react-icons/tb";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const fadeImages = [
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%201.png?alt=media&token=34230e12-26db-4ab1-9233-401847042048"
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%202.png?alt=media&token=25012b75-90c7-4741-9379-a049dafea642"
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ausnihon-japanese-imports.appspot.com/o/slide%203.png?alt=media&token=f70c1da9-2c90-4ecf-a6a5-883ce3d0bab1"
  },
];

const vehicleImages = [
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ama-international-77c43.appspot.com/o/IMG-20240705-WA0061.jpg?alt=media&token=2413c49e-7133-43a7-a3e9-121793bacbb4",
    alt: "Bulldozer",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ama-international-77c43.appspot.com/o/IMG-20240705-WA0012.jpg?alt=media&token=3a0502c7-da91-494f-8cde-a66f99f5cf42",
    alt: "Tractor",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ama-international-77c43.appspot.com/o/IMG-20240705-WA0014.jpg?alt=media&token=b34b539b-a9df-44e7-9f5d-d5b05e1fb714",
    alt: "Crane",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ama-international-77c43.appspot.com/o/IMG-20240705-WA0022.jpg?alt=media&token=f7b23c4d-f638-4556-aa4d-95a58bb72710",
    alt: "Forklift",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ama-international-77c43.appspot.com/o/IMG-20240705-WA0039.jpg?alt=media&token=3abb88e6-4e44-4951-9740-9955a3ff9951",
    alt: "Car",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ama-international-77c43.appspot.com/o/WhatsApp%20Image%202024-07-05%20at%2010.33.14_aae7bcd1.jpg?alt=media&token=189ff421-1626-4a30-9794-b7858d470163",
    alt: "Car",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ama-international-77c43.appspot.com/o/IMG-20240705-WA0016.jpg?alt=media&token=ef718398-c952-45b5-a12d-b737a4d3ed21",
    alt: "Car",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ama-international-77c43.appspot.com/o/IMG-20240705-WA0046.jpg?alt=media&token=4bc4c168-91fa-4264-a7a0-d4ec48c8f886",
    alt: "Car",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/ama-international-77c43.appspot.com/o/IMG-20240705-WA0055.jpg?alt=media&token=37838602-38be-4762-a59d-26d4e0720824",
    alt: "Car",
  },
];

const Home = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="Home">
        <div className="image-text-section">
          <div className="home-intro">
            <span>{t("welcome")}</span>
          </div>
          <div className="home-description">
            <p>
              {t("description")}            
            </p>
          </div>
        </div>
        <div className="intro">
          <div>{t("offering")}</div>
        </div>
        <div className="slide-container">
          <Fade>
            {fadeImages.map((fadeImage, index) => (
              <div key={index}>
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={fadeImage.url}
                />
              </div>
            ))}
          </Fade>
        </div>
        <div className="home-sections" id="sections">
          <div className="section-categories">
            {programsData.map((program) => (
              <div className="category">
                <span className="offering-heading">{t(`programs.${program.displayKey}.heading`)}</span>
                <span className="offering-description">{t(`programs.${program.displayKey}.details`)}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="images-section">
          <div className="intro">
            <span>{t("products")}</span>
          </div>
          <div className="vehicle-images">
            {vehicleImages.map((vehicleImage, index) => (
              <div className="vehicle-image" key={index}>
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={vehicleImage.url}
                  alt={vehicleImage.alt}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
