export const programsData = [
  {
    displayKey: "construction_machinery",
    heading: "Construction Machinery",
    details: "Experience the power and precision of our advanced construction machinery, designed to handle the toughest jobs with ease.",
  },
  {
    displayKey: "used_trucks",
    heading: "Used Trucks",
    details: "Japan is one of the largest automobile manufacturing countries in the world. We will provide you with your desired truck.",
  },
  {
    displayKey: "agricultural_machinery",
    heading: "Agricultural Machinery",
    details: "Boost your agricultural productivity with our range of efficient and durable farming equipment, tailored to modern agricultural demands.",
  },
  {
    displayKey: "auto_vehicles",
    heading: "Auto Vehicles",
    details: "Discover our collection of reliable and high-performance auto vehicles, engineered to offer safety and comfort.",
  },
];