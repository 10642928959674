import React from "react";
import "./Contact.css";
import { useTranslation } from "react-i18next";
import { FaMapMarkerAlt } from "react-icons/fa";
import {
  BsFillTelephoneFill,
  BsEnvelopeFill,
  BsGlobeAsiaAustralia,
} from "react-icons/bs";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="Contact">
        <div className="image-text-section">
          <div className="home-intro">
            <span>{t("contact_us")}</span>
          </div>
        </div>
        <div className="contact-section">
          <div className="map-section">
            <div className="gmap-frame">
              <iframe
                title="location"
                className="iframe"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26666.026086755974!2d130.67211516767256!3d33.33833271385162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35410b3a4702a3dd%3A0xeb92acf834c840c9!2s888%20Tanushimarumachi%20Tanushimaru%2C%20Kurume%2C%20Fukuoka%20839-1233%2C%20Japan!5e0!3m2!1sen!2slk!4v1720277209459!5m2!1sen!2slk"                width="600"
                height="450"
                style={{ border: "3" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="contact-details">
            <div className="contact-heading">{t("contact_details")}</div>
            <div className="contact-item">
              <i>
                <FaMapMarkerAlt />
              </i>
              <p>
                <b>{t("contact_company")}</b> <br />
                {t("contact_address1")}
                <br />{t("contact_address2")}
                <br />{t("contact_address3")}
                <br />{t("contact_address4")}
                <br />{t("contact_address5")}
              </p>
            </div>
            <div className="contact-item">
              <i>
                <BsFillTelephoneFill />
              </i>
              <p>+81 80 275 91117</p>
            </div>
            <div className="contact-item">
              <i>
                <BsEnvelopeFill />
              </i>
              <p>amainternationaljp@gmail.com</p>
            </div>
            <div className="contact-item">
              <i>
                <BsGlobeAsiaAustralia />
              </i>
              <a href="https://amaint.jp/">www.amaint.jp</a>
            </div>
            <div className="contact-item">
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
