import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Header.css";
import Logo from "../../assets/logo-black.png";
import { FaBars, FaTimes, FaGlobe } from "react-icons/fa";

const navLinks = [
  {
    path: "/",
    displayKey: "home",
  },
  {
    path: "/about",
    displayKey: "about",
  },

  {
    path: "/contact",
    displayKey: "contact",
  },
];

const Header = () => {
  const { t, i18n } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  const handleMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguageDropdownOpen(false);
  };

  return (
    <div>
      <div className="header">
        <div className="logo-wrapper">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <ul className="header-menu">
          {/* <li><a href='../Home/Home'>Home</a></li>
            <li><a href='../Stocks/Stocks'>Stocks</a></li>
            <li><a href=''>About</a></li>
            <li><a href=''>Contact</a></li> */}
          {/* <li><a href='../Home/Home'>Admin</a></li> */}
          {navLinks.map((item, index) => (
            <NavLink to={item.path} className="nav-item" key={index}>
              {t(item.displayKey)}
            </NavLink>
          ))}
          <div className="language-selector">
            <div className="dropdown">
            <button className="dropdown-toggle" onClick={toggleLanguageDropdown}>
              <FaGlobe className="globe-icon" />
            </button>
            {languageDropdownOpen && (
              <ul className="menu">
                <li className="menu-item">
                  <button className="dropdown-item" onClick={() => changeLanguage("en")}>
                    English
                  </button>
                </li>
                <li className="menu-item">
                  <button className="dropdown-item" onClick={() => changeLanguage("jp")}>
                    日本語
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
        </ul>
        <i className="menu-button" onClick={handleMenuToggle}>
          {mobileMenuOpen ? <FaTimes /> : <FaBars />}
        </i>
      </div>
      <div className={`mobile-menu ${mobileMenuOpen ? "open" : ""}`}>
        <ul className="mobile-items">
          {navLinks.map((item, index) => (
            <NavLink to={item.path} className="nav-item" key={index}>
              {t(item.displayKey)}
            </NavLink>
          ))}
        </ul>
        <div className="mobile-language-selector">
          <button onClick={() => changeLanguage("en")}>English</button>
          <button onClick={() => changeLanguage("jp")}>日本語</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
